import { tailwind } from '@theme-ui/presets'

export default {
  ...tailwind,
  colors: {
    ...tailwind.colors,
    primary: '#30373f',
    secondary: '#d0bf91',
    darkBlue: '#1d2228'
  },
  fonts: {
    ...tailwind.fonts,
    body: `"Lato", ${tailwind.fonts.sans}`,
    heading: `"Raleway", ${tailwind.fonts.sans}`,
    script: `"PT Sans", ${tailwind.fonts.serif}`
  },
  text: {
    script: {
      fontFamily: 'script',
      fontStyle: 'italic',
      lineHeight: 'tight'
    }
  },
  styles: {
    ...tailwind.styles,
    root: {
      ...tailwind.styles.root,
      fontWeight: 'light',
      color: 'primary',
      a: {
        textDecoration: "none",
        '&:hover': {
          textDecoration: "underline",
          cursor: "pointer"
        }
      },
      form: {
        input: {
          fontFamily: 'body'
        },
        textarea: {
          fontFamily: 'body'
        },
        select: {
          fontFamily: 'body'
        }
      }
    },
    h1: {
      ...tailwind.styles.h1,
      a: {
        '&:hover': {
          textDecoration: "none"
        }
      }
    },
    h6: {
      ...tailwind.styles.h6,
      fontSize: 0
    }
  },
  layout: {
    container: {
      mx: 'auto',
      maxWidth: ["640px", "768px", "1024px", "1280px"] 
    }
  },
  forms: {
    select: {
      py: 2,
      px: 3,
      borderRadius: "default",
      appearance: "none",
      lineHeight: "tight",
      backgroundColor: "gray.2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "gray.2",
      color: "gray.7",
      "&:focus": {
        outline: "none",
        borderColor: "white",
        backgroundColor: "white"
      },
      svg: {
        fill: 'black'
      }
    }
  }
}